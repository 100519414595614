import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})

/**
 * NOTHING TO BE CHANGED MANUALLY HERE!
 * ALSO NOT VERSIONING. CHANGE VERSIONING IN UPDATE-HELPER-SCRIPT!
 */
export class StrHlp {
  // changed-by-script vars
  static CLOUD_PATH = "TESTHUB";
  static APP_NAME = "MAGA Hub";
  static APP_URL = "magahub.app";

  static COMMUNITY_NAME = "MAGA";
  static COMMUNITY_NAME_2 = "Trump";
  static COMMUNITY_NAME_3 = "Patriots";

  static ALLOWS_NSFW = false;

  static DONATION_URL = "https://buy.stripe.com/bIYfZS310gwl9sAfYZ";
  static DONATION_TEXT = "Our mission is to protect free speech. We refuse to censor your speech, and as a result, Google and other ad partners have stopped doing business with us. Be a hero for free speech and help us stay online. God bless America and the 1st Amendment!";
  static DONATION_IMAGE_URL = "https://odhub.b-cdn.net/HubData%2FHubImages%2Fads%2Fsystemads%2Fmagahub_donation_ad_1_1100x1100.webp?alt=media&token=b5855df8-c067-4eb4-bad8-01ab4a2d8a60";

  static get GO_PREMIUM_AD_TEXT() {
    return `Become a **verified member**! Get a blue check next to your name and remove all ads. Plus, you’ll be helping ${StrHlp.APP_NAME} to become independent of advertising partners.`
  }

  static VERSION_CODE = 311;
  static VERSION_STRING = "5.3.0.2";
  // --

  // getter methods for accessing in template

  getAppName(): string {
    return StrHlp.APP_NAME
  }
  getCommunityName(): string {
    return StrHlp.COMMUNITY_NAME
  }
  getCloudPath(): string {
    return StrHlp.CLOUD_PATH
  }
  getVersionCode(): number {
    return StrHlp.VERSION_CODE
  }
  getVersionString(): string {
    return StrHlp.VERSION_STRING
  }
  getAllowsNSFW(): boolean {
    return StrHlp.ALLOWS_NSFW
  }
  getDonationText() {
    return StrHlp.DONATION_TEXT
  }
  getDonationImageURL() {
    return StrHlp.DONATION_IMAGE_URL
  }

  // --

  constructor() {}

  // ***************************************************
  // ***************************************************
  // ***************************************************
  /**
   * DEPRECATED STUFF BELOW, NO LONGER USED
   */
  // ***************************************************
  // ***************************************************
  // ***************************************************

  //static ANDROID_APP_IN_PLAY_STORE_LINK: string = "https://play.google.com/store/apps/details?id=org.toedi.";
  //static KEYWORDS_FOR_SEO: string = "";

  //static ANDROID_APP_IN_PLAY_STORE_LINK: string = "https://play.google.com/store/apps/details?id=org.toedi.";

  //static getSiteDesc(): string {
  //return `${StrHlp.APP_NAME} is THE social network for the ${StrHlp.COMMUNITY_NAME} community. On ${StrHlp.APP_NAME}, images and videos are exciting and genuine. ${StrHlp.APP_NAME} makes finding like-minded people, chatting, sharing images & thoughts very simple. From your morning coffee to your evening routine, ${StrHlp.APP_NAME} has the content that is guaranteed to make your day.`;
  //}
}
